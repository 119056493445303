import { Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import HomePage from './pages/home';
import { useUserContext } from './contexts/user-context';
//import { useInitialization } from './hooks/initialization';

function App() {
  //const { isLoading } = useInitialization();
  const { user } = useUserContext();
  const loggedIn = user && user.userId && user.idToken;

  return (
    <Routes>
      <Route path="/" element={loggedIn ? <HomePage /> : <Navigate to={"login"} replace /> } />
      <Route path="/login" element={loggedIn ? <Navigate to={'/'} replace /> : <LoginPage /> }/>
      <Route path="/register" element={loggedIn ? <Navigate to={'/'} replace /> : <RegisterPage /> }/>
      <Route path="*" element={<Navigate to={loggedIn ? "/" : "login"} replace />} />
    </Routes>
  );
}

export default App;