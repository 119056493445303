import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BasicButtonProps } from '../types';

const InfoButton = ({
  style,
  id,
  label,
  onClick,
  readOnly
}: BasicButtonProps) => {
  return (
    <Button
      variant='link'
      className='btn form-control'
      type='button'
      disabled={readOnly}
      style={style ? style : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={ onClick }> {label}
    </Button>
  );
}

export default InfoButton;