import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import EmailInput from '../components/inputs/email';
import PasswordInput from '../components/inputs/password';
import GeneralSubmitButton from '../components/buttons/general-submit';
import InfoButton from '../components/buttons/info';
import { loginUser } from '../controllers/users.js';
import { loginFormDataProps } from '../components/data-types';
import { isLoginFormDataValid } from '../utils/validations';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/user-context';

const LoginPage = () => {
  const { setUser } = useUserContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState<boolean>(false);
  const [formData, setFormData] = useState<loginFormDataProps>({
    email: '',
    password: '',
    remainLoggedIn: false
  });
  function setFieldValue(key: string, value: any): void {
    setFormData(existingValues => ({
      ...existingValues,
      [key] : value
    }))
  }
  const [message, setMessage] = useState<string>('');
  const afterLoginClicked = () => {
    setValidated(true);
    if (isLoginFormDataValid(formData)) {
      loginUser(formData, setMessage, setUser)
    }
  };
  return  (
    <div>
      <Container 
        className='auto-centered'>
        <Form noValidate validated={validated}>
          <Container
            style={{
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              paddingTop: '1em',
              paddingLeft: '2.5em',
              paddingRight: '2.5em',
              paddingBottom: '1.5em',
              display: 'inline-block'
            }}>
            <Row>
              <EmailInput
                id='login-page-email-input'
                label='Email'
                fieldName='email'
                value={formData.email}
                required={true}
                placeholder='gregor@mandel.com'
                setFieldValue={setFieldValue}
                colWidth={12}
                readOnly={false}
                invalidMessage='Please enter an email address'/>
            </Row>
            <Row>
              <PasswordInput
                id='login-page-password-input'
                label='Password'
                fieldName='password'
                placeholder='g4RD3n1ngM0nK'
                value={formData.password}
                required={true}
                setFieldValue={setFieldValue}
                colWidth={12}
                readOnly={false}
                invalidMessage='Please enter a password'
                showCheckbox={true}
                checkboxLabel='Remain logged in'
                checkboxChecked={formData.remainLoggedIn}
                checkboxToggle={() => setFieldValue('remain_logged_in',!formData.remainLoggedIn)}/>
            </Row>
            <Row className="align-items-end">
              <Col>
                <GeneralSubmitButton
                  label='Login'
                  onClick={() => afterLoginClicked()}/>
              </Col>
            </Row>
          </Container>
          <Container>
            <InfoButton
              id='login-nav-button'
              readOnly={false}
              label='Register'
              onClick={() => navigate('/register')}/>
          </Container>
        </Form>
      </Container>
    </div>
  )
}

export default LoginPage;