import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import AppNavBar from '../components/widgets/nav-bar';
import CollectionsManager from '../components/widgets/collections-manager';
import { UserHomePageProps } from '../components/component-types';
import { getAndSetUserCollections } from '../controllers/data';
import { useUserContext } from '../contexts/user-context';
import { collectionProps } from '../components/data-types';

const HomePage = ({}: UserHomePageProps) => {
  const { user } = useUserContext();
  const [userCollections, setUserCollections] = useState<Array<collectionProps>>([]);
  useEffect(
    () => {getAndSetUserCollections(user, setUserCollections)} ,
    [user]
  );
  //mqttConnectionTest();
  return (
    <div>
      <AppNavBar/>
      <Container
        style={{
          paddingTop: '20px'
        }}>
        <CollectionsManager
          label='My Collections'
          userCollections={userCollections}/>
      </Container>
    </div>
  )
};

export default HomePage;