import { isEmpty } from './lib.js';
import { loginFormDataProps, registerFormDataProps } from '../components/data-types';

export function isLoginFormDataValid(
  formData: loginFormDataProps
) : boolean {
  return !(isEmpty(formData.email) || isEmpty(formData.password))
}

export function isRegisterFormDataValid(
  formData: registerFormDataProps
) : boolean {
  return !(isEmpty(formData.email) || isEmpty(formData.password) || isEmpty(formData.repeatPassword) || (formData.password !== formData.repeatPassword))
}