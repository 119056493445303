import axios from 'axios';
import { setCookie } from 'typescript-cookie';
import { authServerAddress } from '../configs/env';
import { admin_user_id } from '../secrets';

export async function loginUser(formData, setMessage, setUser) {
  const fullPath = `${authServerAddress}/user/login/password/`;
  const userReqBody = {
    "email": formData.email,
    "password": formData.password,
    "admin_user_id": admin_user_id,
    "group_name": "oasis-users",
    "session": true
  }
  try {
    axios.post(fullPath, userReqBody)
      .then(response => {
        const body = response.data;
        if (body.succeeded) {
          const userData = {
            "userId": body.data.user_id,
            "idToken": body.data.id_token
          }
          console.log(`received userData: ${userData}`);
          setUser(userData);
          return setMessage(body.message);
        }
        else {
          return setMessage(body.message);
        }
      });
  } catch (errors) {
    return console.log(errors);
  }
}

export async function registerUser(formData, setMessage) {
  const fullPath = `${authServerAddress}/user/create_account/`;
  const unformattedBody = {
    "email": formData.email,
    "password": formData.password,
    "admin_user_id": admin_user_id,
    "group_name": "oasis-users"
  }
  if(formData.password !== formData.repeatPassword) {
    setMessage("Passwords must match");
  } else {
    try {
      axios.post(fullPath, unformattedBody)
        .then(response => {
          const body = response.data;
          console.log(`body = ${body}`);
          if (body.succeeded) {
            return setMessage(body.message);
          }
          else {
            return setMessage(body.message);
          }
        });
    } catch (errors) {
      return console.log(errors);
    }
  } 
}