import { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { newCollectionProps, collectionProps } from '../data-types';
import { CollectionsManagerProps } from '../component-types'; 
import CollectionsGrid from './collections-grid';
import AddCollectionForm from '../forms/add-collection';

function CollectionsManager({label, userCollections}: CollectionsManagerProps) {
  const [selectedCollectionId, setSelectedCollectionId] = useState<number>(-1);
  const [addingCollection, setAddingCollection] = useState<boolean>(false);
  const [newCollection, setNewCollection] = useState<newCollectionProps>({
    name: ""
  });
  const setFieldValue = (keyOrObject: any, value: any):void => {
    if (typeof keyOrObject === "object") {
      setNewCollection((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewCollection((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };
  const toggleAddingCollection = () => {
    return setAddingCollection(!addingCollection);
  }
  return (
    (
      <div className='CollectionsManager__Page'>
        {(
          addingCollection && <div className='AddCollection__Form'>
            <AddCollectionForm
              newCollection={newCollection}
              setFieldValue={setFieldValue}
              toggleAddingCollection={toggleAddingCollection}/>
          </div>
        )}
        {(
          !addingCollection && <div className='CollectionsManager__Widget'>
          <Row>
            <Col xl={12}>
            <h2>Welcome to the Oasis Dashboard!</h2>
            </Col>
          </Row>
          <Stack direction='horizontal' gap={3}>
            <div className='lgHeader'> {label} </div>
            <div className='ms-auto'>
              <div className='medium-padding'/>
              <Button
                variant='link'
                onClick={() => toggleAddingCollection()}>
                Add New Collection
              </Button>
            </div>
          </Stack>
          {/* <CollectionsGrid
            userCollections={userCollections}
            showWhen={!addingCollection}/> */}
        </div>
        )}
      </div>
    )
  ); 
}

export default CollectionsManager;