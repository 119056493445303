import axios from 'axios';
import { dataServerAddress } from '../configs/env';
import { admin_user_id } from '../secrets';
import { newCollectionProps, collectionProps, userProps } from '../components/data-types';

export const submitCollection = async (
  collection: newCollectionProps,
  user: userProps,
  callback?: {():void}
):Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/create/`;
  const reqBody = {
    "session": {
      "user_id": user.userId,
      "id_token": user.idToken,
      "admin_id": admin_user_id,
      "group_name": "oasis-users"
    },
    "new_collection_name": collection.name
  };
  try {
    axios.post(fullPath, reqBody)
      .then(response => {
        const resBody = response.data;
        if (resBody.succeeded) {
          return callback;
        }
        else {
          return callback;
        }
      });
  } catch (errors) {
    return console.log(errors);
  }
}

export const getAndSetUserCollections = (
  user: userProps,
  setCollections: {(collections: any):void}
):Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/fetch/`;
  const reqBody = {
    "user_id": user.userId,
    "id_token": user.idToken,
    "admin_id": admin_user_id,
    "group_name": "oasis-users"
  };
  const getAndSet = async () => {
    try {
      const response = await axios.post(fullPath, reqBody);
      if(response && response.data) {
        return setCollections(response.data);
      }
    } catch (errors) {
      return console.error(errors);
    }
  }

  return getAndSet();
}