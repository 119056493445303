import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import EmailInput from '../components/inputs/email';
import PasswordInput from '../components/inputs/password';
import GeneralSubmitButton from '../components/buttons/general-submit';
import InfoButton from '../components/buttons/info';
import { registerUser } from '../controllers/users.js';
import { registerFormDataProps } from '../components/data-types';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<registerFormDataProps>({
    email: '',
    password: '',
    repeatPassword: ''
  });
  function setFieldValue(key: string, value: any): void {
    setFormData(existingValues => ({
      ...existingValues,
      [key] : value
    }))
  }
  const [message, setMessage] = useState<string>('');
  return  (
    <div>
      <Alert 
        variant='danger'
        style={{
          visibility: message ? 'visible' : 'hidden'
        }}> { message } </Alert>
      <Container 
        className='auto-centered'>
        <Form>
          <Container
            style={{
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              paddingTop: '1em',
              paddingLeft: '2.5em',
              paddingRight: '2.5em',
              paddingBottom: '1.5em',
              display: 'inline-block'
            }}>
            <Row>
              <EmailInput
                id='login-page-email-input'
                label='Email'
                fieldName='email'
                value={formData.email}
                required={true}
                placeholder='gregor@mandel.com'
                setFieldValue={setFieldValue}
                colWidth={12}
                readOnly={false}/>
            </Row>
            <Row>
              <PasswordInput
                id='login-page-password-input'
                label='Password'
                fieldName='password'
                placeholder='g4RD3n1ngM0nK'
                value={formData.password}
                required={true}
                setFieldValue={setFieldValue}
                colWidth={12}
                readOnly={false}/>
            </Row>
            <Row>
              <PasswordInput
                id='login-page-repeat-password-input'
                label='Re-Enter Password'
                fieldName='repeatPassword'
                placeholder='g4RD3n1ngM0nK'
                value={formData.repeatPassword}
                required={true}
                setFieldValue={setFieldValue}
                colWidth={12}
                readOnly={false}/>
            </Row>
            <Row className="align-items-end">
              <Col>
                <GeneralSubmitButton
                  label='Register'
                  onClick={() => registerUser(formData, setMessage)}/>
              </Col>
            </Row>
          </Container>
          <Container>
            <InfoButton
              id='register-account-nav-button'
              readOnly={false}
              label='Login'
              onClick={() => navigate('/login')}/>
          </Container>
        </Form>
      </Container>
    </div>
  )
}

export default RegisterPage;