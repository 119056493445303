import Button from 'react-bootstrap/Button';
import { GeneralSubmitButtonProps } from '../component-types';

const GeneralSubmitButton = ({label, onClick}: GeneralSubmitButtonProps) => {
  return (
    <Button
      variant='success'
      className='btn form-control'
      type='button'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={ onClick }> {label}
    </Button>
  );
}

export default GeneralSubmitButton;