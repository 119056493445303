import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import SingleCheckbox from './single-checkbox';
import { setFieldValueProps, PasswordInputProps } from '../types';
import { eyeIcon, slashEyeIcon } from '../objects/icons';

const PasswordInput = ({
  style,
  id,
  label,
  fieldName,
  value,
  required,
  placeholder,
  setFieldValue,
  colWidth,
  readOnly,
  invalidMessage,
  showCheckbox,
  checkboxLabel,
  checkboxChecked,
  checkboxToggle
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggle = () => {
    setShowPassword(!showPassword)
  };
  return (
    <Form.Group 
      as={Col}
      xl={colWidth}
      className='form-group'
      style={style ? style : {
        marginBottom: '0.75em'
      }}
      htmlFor={id}>
      <Form.Label>
        { label + ':'}
      </Form.Label>
      <InputGroup>
        <Form.Control
          id={id}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder ? placeholder : label}
          value={value}
          onChange={e => { setFieldValue(fieldName,e.target.value) }}
          disabled={readOnly === true}
          required={required}/>
        <Button variant="secondary" onClick={toggle}>
          {
            showPassword ? slashEyeIcon : eyeIcon
          }
        </Button>
        <Form.Control.Feedback className='small-font' type="invalid">
          <div className='small-font'>
            { invalidMessage }
          </div>
      </Form.Control.Feedback>
      </InputGroup>
      {
        showCheckbox ? 
        (
          <SingleCheckbox
            id={id + '-checkbox'}
            label={checkboxLabel!}
            checked={checkboxChecked!}
            toggle={() => checkboxToggle ? checkboxToggle() : {}}/>
        ) :
        ( <div/> )
      }
    </Form.Group>
  );
}

export default PasswordInput;